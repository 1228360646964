import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/journal.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Trying to stay positive and creative during the stay-at-home order. Its pretty rough right now but hoping to get the song to completion soon. Need MOAR COWBELLS!`}</p>
    <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/813407629&color=%237c7c7c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{
      "fontSize": "10px",
      "color": "#cccccc",
      "lineBreak": "anywhere",
      "wordBreak": "normal",
      "overflow": "hidden",
      "whiteSpace": "nowrap",
      "textOverflow": "ellipsis",
      "fontFamily": "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
      "fontWeight": "100"
    }}><a href="https://soundcloud.com/moomooking" title="MooMooKing" target="_blank" style={{
        "color": "#1a1a1a",
        "textDecoration": "none"
      }}>MooMooKing</a> · <a href="https://soundcloud.com/moomooking/05-04-2020-dubstep-idea" title="05-04-2020-Dubstep Idea" target="_blank" style={{
        "color": "#1a1a1a",
        "textDecoration": "none"
      }}>05-04-2020-Dubstep Idea</a></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      